import * as constant from './constants';

export function getBannerExp() {
  return {
    type: constant.GET_BANNER_EXP,
  };
}

export function loadExpBannerSuccess(payload) {
  return {
    type: constant.EXP_LOAD_BANNER_SUCCESS,
    payload,
  };
}

export function loadExpBannerFailed(payload) {
  return {
    type: constant.EXP_LOAD_BANNER_FAILED,
    payload,
  };
}
