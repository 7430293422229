import { setExpiryApi, setNodeTokenApi, setUserEmailApi } from 'api/utils';
import axios from 'axios';
import { CART_MS_URL } from 'config/lambda';
import { history } from 'configureStore';
import localStore from 'constant/localStore';
import { default as URL, default as urls } from 'constant/urls';
import { createAddress, updateAddress } from 'containers/Account/actions';
import * as checkoutActions from 'containers/Checkout/actions';
import { updateShippingAddress } from 'containers/Checkout/actions';
import cartHelper from 'containers/Checkout/helpers/cart';
import * as mapper from 'containers/Checkout/helpers/mapper';
import * as promotionHelper from 'containers/Checkout/helpers/promotions';
import { getSplashAd } from 'containers/Home/actions';
import * as globalActions from 'containers/Landers/actions';
import { rehydrateStoreViewCode } from 'containers/Landers/actions';
import { SYNC_CART_ADDRESS_CHANGED } from 'containers/Landers/constants';
import config from 'global-config';
import checkoutCartSaga from './screens/CheckoutCart/saga';
import { ctViewCart } from 'utils/clevertap/ctEvent';
import {
  call,
  put,
  takeLatest,
  select,
  delay,
  all,
  take,
  race,
  takeEvery,
  debounce,
} from 'redux-saga/effects';
import request, {
  getAccessToken,
  getStoreCode,
  getStoreView,
  setAccessToken,
  setStoreCode,
} from 'utils/request';
import { cloneDeep, get, findIndex, map, each, isEmpty, size } from 'lodash';
import * as navigation from 'utils/navigation';
import { SCREEN, PAYMENT_METHOD, SPLASH_ADS_LOCATION } from 'global-constant';
import {
  cartHasMembership,
  checkCartHasOnlyMembershipSku,
  checkMembershipIsExpired,
  checkUserIsMembership,
  getCartData,
  getCurrentUser,
} from 'utils/validate';
import { showError } from 'utils/notification';
import { formatDateToTimeZone, toJson } from 'utils/helper';
import * as modalActions from 'containers/Modals/actions';
import * as membershipActions from 'containers/Membership/actions';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import { submitPaymentForm } from 'utils/form';
import {
  makeSelectCurrentLocation,
  makeSelectCurrentUser,
  makeSelectGlobalConfig,
  makeSelectRouter,
} from 'containers/Landers/selectors';
import { makeSelectIsLoginWithMayaCC } from 'containers/Modals/slices/renewMaya';
import { loadItem, saveItem } from 'helper/LocalStorageHelper';
import { getProgressBar } from './screens/CheckoutCart/actions';
import dayjs from 'dayjs';
import { trackEvent, trackingEvent } from 'utils/firebase';
import { isSpecialInstruction } from 'utils/products';
import { getSpecialInstructionValue } from 'utils/cart';
import {
  onShowLoginWithMayaCCSuccess,
  setIsLoginWithMayaCC,
  setShowLinkMembershipCard,
} from 'containers/Modals/slices/renewMaya/slice';

import { ga4AddPaymentInfo } from 'utils/ga4/ga4AddPaymentInfo';
import { ga4AddShippingInfo } from 'utils/ga4/ga4AddShippingInfo';

import { req } from 'utils/req';

import { mapDatastoreToAddress } from 'utils/helper';
import { applyRebate } from './rtk/action';

const RETRY_LIMIT = 2;
const RETRY_DELAY = 3000;

axios.interceptors.response.use(undefined, (err) => {
  const { config, message } = err;
  if (!config || !config.retry) {
    return Promise.reject(err);
  }

  // retry while Network timeout or Network Error
  if (!(message.includes('timeout') || message.includes('Network Error'))) {
    return Promise.reject(err);
  }

  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, config.retryDelay || 1000);
  });

  return delayRetryRequest.then(() => axios(config));
});

const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Regular expressions for different mobile device types
  if (/android/i.test(userAgent)) {
    return true;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  if (/Windows Phone|IEMobile|WPDesktop/.test(userAgent)) {
    return true;
  }

  return false;
};

function* onCreateCart(action) {
  let requestURL = `${config.lambdaUrl}/guest-carts`;
  let method = 'POST';

  if (getAccessToken()) {
    requestURL = `${config.lambdaUrl}/stores/${getStoreView()}/carts/mine`;
    method = 'GET';
  }

  try {
    const cartMaskId = yield call(req, requestURL, {
      method,
      body: JSON.stringify({}),
    });

    yield put(actions.createCartSuccess(get(cartMaskId, 'data.data')));
    if (action.params.addMembership) {
      yield delay(300);
      const { membershipRequest } = action.params;
      yield put(membershipActions.applyMembership(membershipRequest));
    }

    if (getAccessToken()) {
      yield put(actions.getCartInfo());
    }

    const route = yield select(makeSelectRouter());
    const pathname = route?.location?.pathname;

    if (pathname !== SCREEN.CHECKOUT_PAYMENT_SUCCESS) {
      yield put(actions.getCartTotals());
    }
  } catch (err) {
    yield put(actions.createCartFailed(err));
  }
}

function* onGetCartInfo(action) {
  const getCartMaskId = (state) => selectors.makeSelectCartMaskId()(state);
  const cartMaskId = yield select(getCartMaskId);
  const currentUser = yield select((state) => makeSelectCurrentUser()(state));
  const currentTempItems = yield select((state) =>
    selectors.makeSelectTempItems()(state),
  );

  const { syncProductNoRedirect } = action;

  // prettier-ignore
  let requestURL = `${config.lambdaUrl}/cached/guest-carts/${cartMaskId}/${getStoreView()}`;
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  yield put(
    globalActions.showPartialLoader({
      getCartInfoLoading: true,
    }),
  );

  if (getAccessToken()) {
    requestURL = `${config.lambdaUrl}/stores/${getStoreView()}/carts/mine`;
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // sourceCode: getStoreCode(),
    };
  } else {
    const isGuestCartUpdated = yield select((state) =>
      selectors.makeSelectIsGuestCartUpdated()(state),
    );

    if (isGuestCartUpdated) {
      headers = {
        ...headers,
        'Cache-Control': 'max-age=0',
      };
    }
  }

  const isLoginWithMayaCC = yield select((state) =>
    makeSelectIsLoginWithMayaCC()(state),
  );

  try {
    const result = yield call(req, requestURL, {
      method: 'GET',
      headers,
    });

    const res = get(result, 'data.data');

    let missingItem = [];
    const newTempItems =
      (action.needReloadTotals &&
        !action?.needValidate &&
        currentTempItems.map((val) => {
          const matchItem = res.items.find((item) => val.sku === item.sku);
          if (matchItem) {
            return matchItem;
          }
          missingItem.push(val);
          return val;
        })) ||
      res.items;

    const temptItems = newTempItems.map((val) => ({
      sku: val.sku,
      product_id: val.product_id,
      qty: val.qty,
    }));

    yield put(checkoutActions.updateTempItems(temptItems));
    yield put(
      globalActions.showPartialLoader({
        getCartInfoLoading: false,
      }),
    );
    let cartData = cartHelper.calculateCart(res);

    if (missingItem) {
      cartData.items = cartData.items.concat(missingItem);
    }

    const pathname = window.location.pathname;
    const isInPaymentSuccess = pathname.includes('checkout/onepage/success');

    //
    yield put(actions.getCartInfoSuccess(cartData));
    yield put(actions.getCartRules());

    if (!getAccessToken()) {
      yield put(actions.setIsGuestCartUpdated(false));
    }
    if (res) {
      if (action.needReloadTotals) {
        if (isInPaymentSuccess === false) {
          yield put(actions.getCartTotals());
        }
      }
    }

    if (isInPaymentSuccess === false) {
      yield put(actions.calculateBizRebate());
    }

    if (getAccessToken()) {
      const getNeededMergedItems = (state) =>
        selectors.makeSelectNeededMergedItems()(state);
      const neededMergedItems = yield select(getNeededMergedItems);

      yield put(checkoutActions.getLastOrderOOS());
      // User has items needed to merge to cart
      if (neededMergedItems) {
        const mergedCartItems = cloneDeep(res.items);

        each(neededMergedItems, (neededMergedItem) => {
          const duplicatedItemIdx = findIndex(
            mergedCartItems,
            (cartItem) => neededMergedItem.sku === cartItem.sku,
          );

          if (duplicatedItemIdx > -1) {
            mergedCartItems[duplicatedItemIdx].qty += neededMergedItem.qty;
            mergedCartItems[duplicatedItemIdx].sku = neededMergedItem.sku;
          } else {
            let _neededMergedItem = {
              ...neededMergedItem,
              product_data: neededMergedItem.extension_attributes,
              sku: neededMergedItem.sku,
            };
            mergedCartItems.push(_neededMergedItem);
          }
        });

        const syncProducts = map(mergedCartItems, (cartItem) => {
          const product_data = get(
            cartItem,
            'extension_attributes.product_data',
          );

          const data = {
            ...product_data,
            product_type: cartItem.type_id,
            product_id: cartItem.product_id,
            type_id: cartItem.product_type,
            sku: cartItem.sku,
          };

          return {
            data,
            qty: cartItem.qty,
          };
        });

        yield put(
          actions.syncCartClient(syncProducts, {
            delay: 500,
            needReloadTotals: false,
            includeMembershipItem: true,
          }),
        );
        yield put(actions.syncProductThenGoCheckout());
        // Check if user has items in cart
      } else if (action.redirectTo) {
        if (get(res, 'items.length')) {
          if (action.redirectTo === SCREEN.CHECKOUT_CART) {
            navigation.navigate(action.redirectTo, {
              validateMembership: true,
            });
            // yield put(actions.validateCartItems());
            yield put(
              actions.syncProductThenGoCheckout({
                noRedirectCart: syncProductNoRedirect,
                isReloadCartInfo: syncProductNoRedirect, // same value for now since only using this in sync-cart
              }),
            );
          }
        } else {
          // case: the first time login, we need to check membership

          if (isLoginWithMayaCC) {
            yield put(
              onShowLoginWithMayaCCSuccess({
                title: 'LINKING SUCCESSFUL',
                body: [
                  'Your membership card has been successfully',
                  'linked. You may now proceed on signing up for',
                  'Landers Cashback Everywhere Credit Card.',
                ],
              }),
            );

            return true;
          }
          navigation.navigate('/', {
            validateMembership: true,
          });
        }
      }
      if (isLoginWithMayaCC) {
        const isMember = get(
          currentUser,
          'extension_attributes.membership_info.code',
          null,
        );
        if (!isMember) {
          yield put(setShowLinkMembershipCard(true));
        } else {
          yield put(
            onShowLoginWithMayaCCSuccess({
              title: 'LOGIN SUCCESSFUL',
              body: [
                'Your may now proceed with signing up for your',
                'Landers Cashback Everywhere Credit Card.',
              ],
            }),
          );
        }
        yield put(setIsLoginWithMayaCC(false));
      }

      if (action.needValidate) {
        yield put(actions.validateCartItems());
      }
    }
    //Will only trigger event on cart screen initialization
    if (action?.isInCheckoutPage) {
      yield take(constants.GET_CART_TOTALS_SUCCESS);
      const cartTotals = yield select((state) =>
        selectors.makeSelectCartTotals()(state),
      );
      ctViewCart({
        items: res?.items || [],
        subtotal: cartTotals?.subtotal || 0,
        total: cartTotals?.grand_total || 0,
      });
    }
  } catch (err) {
    const errorJson = toJson(err);
    yield put(
      globalActions.showPartialLoader({
        getCartInfoLoading: false,
      }),
    );
    if (
      err.status === 404 &&
      get(errorJson, 'parameters.fieldName') === 'cartId'
    ) {
      yield put(actions.createCart());
    }
    yield put(actions.getCartInfoFailed(err));
  }
}

function* onGetCartTotals(action) {
  const globalConfig = yield select((state) => makeSelectGlobalConfig()(state));
  const globalCampaign = get(globalConfig, 'campaign');
  const globalStartTime = get(globalCampaign, 'start_time');
  const globalEndTime = get(globalCampaign, 'end_time');
  const globalStatus = get(globalCampaign, 'status');
  const campaignRedirect = get(globalConfig, 'campaign_page_redirection');

  const deviceType = isMobile() ? 'm-site' : 'desktop';
  const getCartMaskId = (state) => selectors.makeSelectCartMaskId()(state);
  const cartMaskId = yield select(getCartMaskId);
  const getPreviousCartTotal = (state) =>
    selectors.makeSelectCartTotals()(state);
  const previousCartTotal = yield select(getPreviousCartTotal);
  const rebateErrorMessage = (state) =>
    selectors.makeSelectRebateErrorMsg()(state);
  // const previousCartTotal = makeSelectCartTotals()(getState());
  //     const rebateErrorMessage = makeSelectRebateErrorMessage()(getState());
  const getSelectedTimeslot = (state) =>
    selectors.makeSelectSelectedTimeslot()(state);
  const selectedTimeslot = yield select(getSelectedTimeslot);

  let requestURL = `${config.lambdaUrl}/guest-carts/${cartMaskId}/totals-information`;

  if (getAccessToken()) {
    requestURL = `${config.lambdaUrl}/carts/mine/totals-information`;
  }

  try {
    const cartData = yield select((state) =>
      selectors.makeSelectCartData()(state),
    );
    const cartItems = get(cartData, 'items') || [];
    const currentLocation = yield select((state) =>
      makeSelectCurrentLocation()(state),
    );
    let townshipId =
      get(currentLocation, 'extension_attributes.township_id') ||
      get(currentLocation, 'barangay.id');

    const isStorePickup = get(selectedTimeslot, 'storeAddress');

    if (isStorePickup) {
      townshipId = get(isStorePickup, 'townshipid', '');
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      // sourcecode: getStoreCode() || '',
      // townshipid: townshipId || '',
      'device-type': deviceType,
    };

    const res = yield call(req, requestURL, {
      retry: RETRY_LIMIT,
      retryDelay: RETRY_DELAY,
      method: 'POST',
      headers,
      data: {
        township_id: townshipId || '',
      },
    });

    // Reload to fix totals-information API in flash sale campaign
    const forceReload =
      !window.location.pathname.includes(urls.checkoutSuccess) &&
      campaignRedirect &&
      globalStatus &&
      dayjs
        .tz()
        .isBetween(
          formatDateToTimeZone(globalStartTime),
          formatDateToTimeZone(globalEndTime),
        ) &&
      Array.isArray(res.data.items) &&
      Array.isArray(cartData.items) &&
      ((res.data.items.length === 0 && cartData.items.length > 0) ||
        (cartData.items.length > 0 &&
          res.data.items.length > 0 &&
          cartData.items.length !== res.data.items.length));
    if (forceReload) {
      window.location.reload();
      return;
    }

    // fix empty cart or membership when checkout
    let pathname = get(window, 'location.pathname', '');
    if (
      pathname === URL.checkoutDelivery ||
      pathname === SCREEN.CHECKOUT_TIMESLOTS ||
      pathname === SCREEN.CHECKOUT_DELIVERY_ADDRESS ||
      pathname === SCREEN.CHECKOUT_PICKUP ||
      pathname === SCREEN.CHECKOUT_PAYMENT
    ) {
      if (cartItems.length === 0) {
        navigation.navigate(SCREEN.CHECKOUT_CART);
      } else if (!cartHasMembership(cartItems)) {
        const isExpire = checkMembershipIsExpired();
        const isMembership = checkUserIsMembership();
        if (isExpire) {
          navigation.navigate(SCREEN.MEMBERSHIP_LIST, {
            showPopup: 'renew',
          });
        } else if (!isMembership) {
          // If user don't have membership
          navigation.navigate(SCREEN.MEMBERSHIP_LIST, {
            showPopup: 'apply',
          });
        }
      }
    }

    const dataCoupon = yield select((state) =>
      selectors.makeSelectDataCoupon()(state),
    );

    const { data } = res.data;

    if (
      data?.coupon_code &&
      (dataCoupon === undefined || dataCoupon?.success === false)
    ) {
      yield put(
        actions.getVoucherMessage({
          couponCode: data?.coupon_code,
          isValid: true,
        }),
      );
      yield take(constants.GET_VOUCHER_MESSAGE_SUCCESS);
    }

    const screen = window.location.pathname;

    let isSetTempItems = true;

    const isInPaymentSuccess = screen.includes('checkout/onepage/success');

    if (isInPaymentSuccess) {
      // let's not override the tempCartTotal when in payment success
      // so that it can display the previous total-information
      isSetTempItems = false;
    }
    if (pathname !== SCREEN.CHECKOUT_CART && !!rebateErrorMessage) {
      yield put(actions.loadRebateErrorMsg(''));
    }

    //If previous applied rebate has changed and greater than redeemable rebate, will toggle off the apply rebate
    if (
      Object.keys(previousCartTotal).length > 1 &&
      previousCartTotal?.applied_rebate > data?.rebate_balance
    ) {
      yield put(
        actions.loadRebateErrorMsg(
          'Oops! Available rebate points are now lower than your specified amount. Please enter a new amount.',
        ),
      );

      yield put(applyRebate(0));
      if (
        pathname === URL.checkoutDelivery ||
        pathname === SCREEN.CHECKOUT_TIMESLOTS ||
        pathname === SCREEN.CHECKOUT_DELIVERY_ADDRESS ||
        pathname === SCREEN.CHECKOUT_PICKUP ||
        pathname === SCREEN.CHECKOUT_PAYMENT
      ) {
        navigation.navigate(SCREEN.CHECKOUT_CART);
      }
    }

    yield put(actions.getCartTotalsSuccess({ isSetTempItems, ...data }));
  } catch (err) {
    yield put(actions.getCartTotalsFailed(err));
    if (getAccessToken()) {
      yield put(modalActions.showErrorMessageModal(true));
    }
  }
}

function* onValidateCartItems(action) {
  const isLoggedIn = getAccessToken() ? true : false;
  let requestURL = `${config.lambdaValidateUrl}/validate-cart`;

  let cartData = yield select(getCartData);
  let cartItems = get(cartData, 'items');

  let storeCode = getStoreCode();

  const products = cartItems.map((item) => ({
    sku: item.sku,
    qty: item.qty,
  }));

  try {
    const res = yield call(axios, requestURL, {
      method: 'POST',
      data: {
        source: storeCode,
        products,
      },
    });
    const response = res?.data?.data;
    const membershipHasError = get(response, 'membership_has_error', false);

    if (membershipHasError) {
      window.location.reload();
    }

    if (isLoggedIn) {
      yield put(globalActions.getUserInfo({ isShowLoading: false }));
    }

    let invalidQty = response?.invalid_qty || [];

    if (response?.invalid_sku.length > 0) {
      const skus = response?.invalid_sku.map((sku) => {
        const product = cartItems.find((cartItem) => cartItem.sku === sku);

        return {
          product_sku: sku,
          invalid_qty: product?.qty || 1,
        };
      });

      invalidQty = [...invalidQty, ...skus];
    }

    yield put(actions.validateCartItemsSuccess(invalidQty));
    if (invalidQty.length > 0) {
      let maxStock = [];
      let maxQty = [];

      for (let item of invalidQty) {
        const product = cartItems.find(
          (cartItem) => cartItem.sku === item.product_sku,
        );

        if (item && product) {
          if (item.max_qty_allowed < product.qty) {
            maxQty.push(item);
          } else {
            maxStock.push(item);
          }
        }
      }

      if (maxStock.length > 0) {
        yield put(modalActions.showInvalidCartItemsModal(maxStock));
      }

      if (maxQty.length > 0) {
        yield put(modalActions.showMaxCartItemsQtyModal(maxQty));
      }
    }
  } catch (err) {
    yield put(actions.validateCartItemsSuccess([]));
  }
}

function* onGetDeliveryTimeslots({ payload }) {
  const currentUser = getCurrentUser();
  const requestURL = `${config.apiUrl}/ldmultistores/deliverydate?customerId=${currentUser?.id}`;
  yield put(globalActions.showLoader());
  try {
    if (payload?.refreshShippingAddress) {
      yield put(updateShippingAddress(false, { isUpdateAddress: true }));
      yield take(constants.UPDATE_SHIPPING_ADDRESS_SUCCESS);
      yield put(actions.syncCartToMgt());
      yield take(constants.SYNC_CART_MGT_FINISHED);
    }

    const currentLocation = yield select((state) =>
      makeSelectCurrentLocation()(state),
    );

    const townshipId =
      get(currentLocation, 'extension_attributes.township_id') ||
      get(currentLocation, 'barangay.id');

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      sourcecode: getStoreCode() || '',
      townshipid: townshipId || '',
    };
    const res = yield call(axios, requestURL, {
      method: 'GET',
      headers,
    });

    const allTimeslots = get(res.data, '[0].time_slot');

    yield put(globalActions.hideLoader());
    yield put(actions.getDeliveryTimeslotsSuccess(allTimeslots || []));
  } catch (err) {
    showError(get(err, 'message'));
    yield put(globalActions.hideLoader());
    yield put(actions.getDeliveryTimeslotsFailed(err));
  }
}

function* onGetStoresTimeslots(action) {
  const requestURL = `${config.apiUrl}/ldmultistores/deliverydate${
    !getAccessToken() ? '-anonymous' : ''
  }`;
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      sourcecode: action.params.sourcecode || '',
    };
    const res = yield call(axios, requestURL, {
      method: 'GET',
      headers,
    });

    const timeSlots = get(res.data, '[0].time_slot');

    yield put(
      actions.getStoresTimeSlotsSuccess({
        timeSlots: timeSlots || [],
        sourcecode: action.params.sourcecode,
      }),
    );
  } catch (err) {
    yield put(actions.getStoresTimeSlotsFailed(err));
  }
}

function* onValidateDeliveryTimeslot(action) {
  const { isPickup } = action.payload;
  const requestURL = `${config.apiUrl}/ldmultistores/deliverydate/validate`;
  yield put(globalActions.showLoader());

  const selectedTimeSlot = yield select(selectors.makeSelectSelectedTimeslot());
  const currentUser = yield select((state) => makeSelectCurrentUser()(state));
  const pickupAddress = currentUser?.addresses?.find((address) => {
    return address.extension_attributes?.store_pickup;
  });

  const getSelectedTimeslot = (state) =>
    selectors.makeSelectSelectedTimeslot()(state);

  const selectedTimeslot = yield select(getSelectedTimeslot);
  const cartTotals = yield select(selectors.makeSelectCartTotals());
  const couponCode = yield select(selectors.makeSelectCouponCode());

  if (!selectedTimeslot) {
    yield put(globalActions.hideLoader());
    showError('Please select delivery timeslot');
  } else {
    try {
      const res = yield call(request, requestURL, {
        method: 'POST',
        body: JSON.stringify({
          date: selectedTimeslot.date,
          time_id: selectedTimeslot.value,
          location_store: getStoreCode(),
        }),
      });
      ga4AddShippingInfo(cartTotals, isPickup, couponCode);
      if (!res.errors) {
        if (isPickup) {
          let address = mapDatastoreToAddress(
            selectedTimeSlot.storeAddress,
            currentUser,
          );
          if (!isEmpty(pickupAddress)) {
            yield put(
              updateAddress(
                { address: { ...address, id: pickupAddress.id } },
                true,
              ),
            );
          } else {
            yield put(createAddress({ address }, true));
          }
          yield put(checkoutActions.setShippingAddress(address));
        }

        yield put(actions.updateShippingAddress());
        yield put(actions.validateDeliveryTimeslotSuccess(res));
      } else {
        showError('Delivery timeslot invalid');
        yield put(globalActions.hideLoader());
        yield put(actions.validateDeliveryTimeslotFailed(res));
      }
    } catch (err) {
      showError(get(err, 'message') || 'Validate delivery date failed');
      yield put(actions.getDeliveryTimeslots());
      yield put(globalActions.hideLoader());
      yield put(actions.validateDeliveryTimeslotFailed(err));
    }
  }
}

function* onGetShippingMethods(action) {
  const requestURL = `${config.apiUrl}/V1/carts/${action.cartId}/shipping-methods`;
  yield put(globalActions.showLoader());
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    });
    yield put(globalActions.hideLoader());
    yield put(actions.getShippingMethodsSuccess(res));
  } catch (err) {
    yield put(globalActions.hideLoader());
    yield put(actions.getShippingMethodsFailed(err));
  }
}

function* onEstimateShippingMethods(action) {
  const getCartMaskId = (state) => selectors.makeSelectCartMaskId()(state);
  const cartMaskId = yield select(getCartMaskId);
  let requestURL = `${config.baseUrl}/rest/default/V1/guest-carts/${cartMaskId}/estimate-shipping-methods`;
  if (getAccessToken()) {
    requestURL = `${config.baseUrl}/rest/default/V1/carts/mine/estimate-shipping-methods`;
  }

  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({
        address: {
          region: '',
          country_id: 'PH',
          postcode: null,
        },
      }),
    });
    yield put(actions.estimateShippingMethodsSuccess(res));
  } catch (err) {
    yield put(actions.estimateShippingMethodsFailed(err));
  }
}

function* onUpdateShippingAddress(action) {
  const isUpdateAddress = action.params.isUpdateAddress || false;
  const requestURL = `${config.lambdaUrl}/carts/mine/shipping-information`;

  const getShippingAddress = (state) =>
    selectors.makeSelectShippingAddress()(state);
  const address = yield select(getShippingAddress);
  const cartData = yield select(selectors.makeSelectCartData());
  // eslint-disable-next-line
  const cartHasOnlyMembershipSKU = checkCartHasOnlyMembershipSku(
    cartData.items,
  );

  //if (cartData.items.length === 0 || cartHasOnlyMembershipSKU) return

  let townshipId =
    get(address, 'extension_attributes.township_id') ||
    get(address, 'barangay.id');

  const getSelectedTimeslot = (state) =>
    selectors.makeSelectSelectedTimeslot()(state);
  const selectedTimeslot = yield select(getSelectedTimeslot);
  let shippingAddress = mapper.addressToShippingAddress(
    address,
    selectedTimeslot,
  );
  if (get(selectedTimeslot, 'storeAddress.townshipid')) {
    shippingAddress = mapper.mapStoreAddressToShippingAddress(
      shippingAddress,
      selectedTimeslot.storeAddress,
    );
    townshipId = get(selectedTimeslot, 'storeAddress.townshipid');
    yield put(getProgressBar({ townshipIdStorePickup: townshipId }));
  } else {
    yield put(getProgressBar());
  }

  const cartId = yield select((state) => selectors.makeSelectCartId()(state));
  // const currentUser = yield select((state) => makeSelectCurrentUser()(state))

  const customerAddress = get(
    shippingAddress,
    'extension_attributes.township_id',
  )
    ? {
        firstname: get(shippingAddress, 'firstname', ''),
        lastname: get(shippingAddress, 'lastname', ''),
        street: get(shippingAddress, 'street[0]', ''),
        city: get(shippingAddress, 'city', ''),
        region: get(shippingAddress, 'region', ''),
        region_id: get(shippingAddress, 'regionId', ''),
        postcode: get(shippingAddress, 'postcode', ''),
        country_id: get(shippingAddress, 'countryId', ''),
        telephone: get(shippingAddress, 'telephone', ''),
        city_id: get(shippingAddress, 'extension_attributes.city_id', ''),
        township: get(shippingAddress, 'extension_attributes.township', ''),
        township_id: get(
          shippingAddress,
          'extension_attributes.township_id',
          '',
        ),
        location_label: get(
          shippingAddress,
          'extension_attributes.location_label',
          '',
        ),
        landmark: get(shippingAddress, 'extension_attributes.landmark', ''),
        building_name: get(
          shippingAddress,
          'extension_attributes.building_name',
          '',
        ),
        fax: get(shippingAddress, 'fax', ''),
      }
    : null;

  const requestData = {
    cart_id: cartId,
    shipping_carrier_code: 'tablerate',
    shipping_method_code: 'bestway',
    store_source_code: getStoreCode() || '',
    billing_address: customerAddress,
    extension_attributes: {
      amdeliverydate_comment: get(
        selectedTimeslot,
        'amdeliverydate_comment',
        '',
      ),
      amdeliverydate_date: get(selectedTimeslot, 'date', ''),
      amdeliverydate_time: get(selectedTimeslot, 'value', ''),
    },
    shipping_address: customerAddress,
  };

  yield put(globalActions.showLoader());
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      // sourcecode: getStoreCode() || '',
      // townshipid: townshipId || '',
    };

    const res = yield call(req, requestURL, {
      method: 'POST',
      headers,
      data: requestData,
    });

    if (!isUpdateAddress) {
      navigation.navigate(SCREEN.CHECKOUT_PAYMENT);
    }

    yield put(globalActions.hideLoader());
    yield put(actions.updateShippingAddressSuccess(res));
    // update latest cart totals information
    yield put(actions.getCartTotals());
  } catch (err) {
    if (get(err, 'response.status') === 401) {
      history.push('/', { isTokenExpired: true });
    } else {
      yield put(globalActions.hideLoader());
      yield put(actions.updateShippingAddressFailed(err));
    }
  }
}

function* onUpdatePaymentInformation(action) {
  let requestURL = `${config.lambdaUrl}/carts/mine/payment-information`;

  const currentUser = yield select((state) => makeSelectCurrentUser()(state));

  const address = yield select((state) =>
    selectors.makeSelectShippingAddress()(state),
  );

  const cartId = yield select((state) => selectors.makeSelectCartId()(state));

  const paymentMethod = yield select((state) =>
    selectors.makeSelectSelectedPaymentMethod()(state),
  );

  const getSelectedTimeslot = (state) =>
    selectors.makeSelectSelectedTimeslot()(state);
  const selectedTimeslot = yield select(getSelectedTimeslot);
  let cancellationOption = yield select((state) =>
    selectors.makeSelectCancellationOption()(state),
  );

  const cartTotals = yield select((state) =>
    selectors.makeSelectCartTotals()(state),
  );

  const couponCode = yield select((state) =>
    selectors.makeSelectCouponCode()(state),
  );

  let billingAddress = null;

  if (!action.cartHasOnlyMembership) {
    billingAddress = mapper.addressToShippingAddress(address, selectedTimeslot);
  } else {
    cancellationOption = '';
  }

  if (billingAddress) {
    billingAddress = {
      city: get(billingAddress, 'city'),
      company: get(billingAddress, 'company'),
      country_id: get(billingAddress, 'countryId'),
      customer_id: get(billingAddress, 'customerId'),
      fax: get(billingAddress, 'fax'),
      firstname: get(billingAddress, 'firstname'),
      lastname: get(billingAddress, 'lastname'),
      middlename: get(billingAddress, 'middlename'),
      postcode: get(billingAddress, 'postcode'),
      prefix: get(billingAddress, 'prefix'),
      region: get(billingAddress, 'region'),
      region_id: get(billingAddress, 'regionId'),
      same_as_billing: get(billingAddress, 'same_as_billing'),
      save_in_address_book: get(billingAddress, 'save_in_address_book'),
      street: get(billingAddress, 'street'),
      suffix: get(billingAddress, 'suffix'),
      telephone: get(billingAddress, 'telephone'),
      extension_attributes: get(billingAddress, 'extension_attributes'),
    };
  }

  if (!paymentMethod) {
    showError('Please choose payment method');
  } else {
    ga4AddPaymentInfo(cartTotals, paymentMethod.code, couponCode);
    const requestPayload = {
      billing_address: billingAddress,
      cart_id: cartId,
      payment_method: {
        additional_data: !isEmpty(action?.cardInfo)
          ? {
              cancellation_option: cancellationOption,
              card_info: JSON.stringify(action.cardInfo ? action.cardInfo : {}),
            }
          : {
              cancellation_option: cancellationOption,
            },
        method: paymentMethod.code,
        po_number: null,
      },
    };
    yield put(globalActions.showLoader({ isPaymentProcessing: true }));
    try {
      let mgtSyncURL = `${config.lambdaUrl}/sync-cart/mgt`;

      yield call(req, mgtSyncURL, {
        method: 'POST',
        data: {
          cart_id: cartId,
        },
      });

      const deviceType = isMobile() ? 'm-site' : 'desktop';
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'device-type': deviceType,
      };

      const paymentInfoRes = yield call(req, requestURL, {
        method: 'POST',
        data: requestPayload,
        headers,
      });
      const mgtPayload = JSON.stringify(
        get(paymentInfoRes, 'data.data.placeOrderParams', {}),
      );

      const payPlaceOrderPayUrl = mapper.payPlaceOrderPayUrl(paymentInfoRes);

      const payPlacePayload = {
        mgtPayload: mgtPayload,
        urls: payPlaceOrderPayUrl,
        paymentInfoRes: paymentInfoRes,
      };
      yield put(actions.savePayPlaceOrder(payPlacePayload));

      yield take(constants.SAVE_PAY_PLACE_ORDER_SUCCESS);
      const res = yield select((state) =>
        selectors.makeSelectPayPlaceOrderResponse()(state),
      );

      const regex = /[^\d]/gm;
      const mgtRes = res;
      let orderId = '';

      if (regex.test(mgtRes)) {
        orderId = mgtRes.replace(regex, '');
      } else {
        orderId = res;
      }

      yield put(actions.updatePaymentInformationSuccess(orderId));

      if (action?.cardInfo?.saveCard) {
        yield put(modalActions.showCardSavedSuccess(true));
      } else {
        yield put(modalActions.showCardSavedSuccess(false));
      }

      if (
        paymentMethod.code === PAYMENT_METHOD.CASH ||
        paymentMethod.code === PAYMENT_METHOD.FREE
      ) {
        navigation.navigate(SCREEN.CHECKOUT_PAYMENT_SUCCESS);
      } else {
        yield delay(200);
        const params = {
          orderId: orderId,
          customerId: currentUser.id,
        };
        submitPaymentForm(`${config.baseUrl}/gateway/resolver/index`, params);
      }

      yield put(checkoutActions.isSubmittedPayment(false));
    } catch (err) {
      console.log('err', err);
      showError(get(err, 'message') || 'Failed to place order');
      navigation.navigate(SCREEN.CHECKOUT_TIMESLOTS);
      yield put(globalActions.hideLoader());
      yield put(checkoutActions.isSubmittedPayment(false));
      yield put(actions.updatePaymentInformationFailed(err));
    }
  }
}

function* onGetOrderById(action) {
  let requestURL = `${config.apiUrl}/order-detail/mine?order_id=${action.orderId}`;
  yield put(globalActions.showLoader());
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    });
    yield put(actions.getOrderByIdSuccess(res));
    if (action.reloadShippingAddress) {
      // fix bug duplicate address after checkout, we need map new address for checkout flow
      let idAddress = get(res, 'billing_address.customer_address_id');
      let newShippingAddress;
      if (idAddress) {
        newShippingAddress = {
          ...res.billing_address,
          id: idAddress,
        };
      } else {
        newShippingAddress = loadItem(localStore.shippingAddress);
      }
      yield put(checkoutActions.setShippingAddress(newShippingAddress));
      yield put(globalActions.checkLocationSuccess(newShippingAddress));
    }
  } catch (err) {
    yield put(actions.getOrderByIdFailed(err));
    yield delay(500);
    navigation.navigate(SCREEN.CHECKOUT_CART);
  } finally {
    yield put(globalActions.hideLoader());
  }
}

function* onGetPaymentMethods(action) {
  const cartData = yield select((state) =>
    selectors.makeSelectCartData()(state),
  );

  yield put(globalActions.showLoader());

  let mgtSyncURL = `${config.lambdaUrl}/sync-cart/mgt`;
  let paymentMethodsURL = `${config.apiUrl}/carts/mine/landers-payment-methods`;

  yield put(actions.validateCartItems());
  yield take(constants.VALIDATE_CART_ITEMS_SUCCESS);

  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
    };

    yield call(req, mgtSyncURL, {
      method: 'POST',
      data: {
        cart_id: get(cartData, 'id', ''),
      },
      headers,
    });

    yield delay(200);

    const res = yield call(request, paymentMethodsURL, {
      method: 'GET',
    });

    const list = Object.values(get(res, '[0].payment'));
    const mediaPath = get(res, '[0].mediapath');
    const freePayment = list.find(
      (item) => item.code === 'free' && item.is_available,
    );
    const allPaymentMethods = freePayment ? [freePayment] : list;

    yield put(globalActions.hideLoader());
    yield put(actions.getMediaPathSuccess(mediaPath));
    yield put(actions.getPaymentMethodsSuccess(allPaymentMethods));
    const mayaVaultMethod = allPaymentMethods?.find(
      (method) => method?.code === PAYMENT_METHOD?.MAYA_VAULT,
    );

    if (mayaVaultMethod) {
      yield put(actions.setPaymentMethod(mayaVaultMethod));
    }

    if (allPaymentMethods.length === 1) {
      yield put(actions.setPaymentMethod(allPaymentMethods[0]));
    }

    if (!mayaVaultMethod && allPaymentMethods.length > 1) {
      yield put(actions.setPaymentMethod(null));
    }

    if (allPaymentMethods.length === 0) {
      yield put(actions.setPaymentMethod(null));
    }
  } catch (err) {
    yield put(globalActions.hideLoader());
    yield put(actions.getPaymentMethodsFailed(err));
  }
}

function* onApplyCoupon(action) {
  const { couponCode, deviceType, isDeleteMode = false } = action.payload;

  const cartId = yield select((state) => selectors.makeSelectCartId()(state));
  const accessToken = getAccessToken();

  let requestURL = `${config.lambdaUrl}/carts/${cartId}/coupons/${couponCode}`;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'device-type': deviceType,
  };

  if (accessToken) {
    headers.Authorization = `Bearer ${getAccessToken()}`;
  }

  yield put(globalActions.showLoader());

  try {
    yield put(globalActions.showLoader());

    yield call(req, requestURL, {
      method: isDeleteMode ? 'DELETE' : 'PUT',
      headers,
    });

    let dataUpdate = isDeleteMode ? '' : couponCode;

    yield put(globalActions.hideLoader());

    if (isDeleteMode) {
      yield all([
        put(
          actions.applyCouponSuccess({
            couponCode,
          }),
        ),
        put(
          actions.getVoucherMessageSuccess({
            success: 'Your Coupon was successfully removed',
            error: false,
          }),
        ),
      ]);
      yield delay(2000);
      yield all([
        put(
          actions.applyCouponSuccess({
            couponCode: dataUpdate,
          }),
        ),
        put(
          actions.getVoucherMessageSuccess({
            success: false,
            error: false,
          }),
        ),
      ]);
    } else {
      yield all([
        put(
          actions.applyCouponSuccess({
            couponCode,
          }),
        ),
        put(
          actions.getVoucherMessage({
            couponCode,
            isValid: true,
          }),
        ),
      ]);
      yield take(constants.GET_VOUCHER_MESSAGE_SUCCESS);
    }

    yield put(
      actions.getCartInfo({
        needReloadTotals: true,
      }),
    );
    yield take(constants.GET_CART_INFO_SUCCESS);

    const route = yield select(makeSelectRouter());
    const pathname = route?.location?.pathname;
    if (pathname === SCREEN.CHECKOUT_PAYMENT) {
      yield delay(100);
      yield put(actions.getPaymentMethods());
    }
  } catch (err) {
    const errorMessage = get(err, 'response.data.error.message', '');
    yield put(
      actions.getVoucherMessage({
        couponCode,
        isValid: false,
        errorMessage,
      }),
    );
    yield take(constants.GET_VOUCHER_MESSAGE_SUCCESS);
    yield put(globalActions.hideLoader());
  }
}

function* onGetVoucherMessage(action) {
  const { couponCode, isValid, errorMessage = false } = action.payload;
  const requestURL = `${config.apiUrl}/coupon/${couponCode}`;

  const defaultErrorMessage =
    'Sorry, voucher is not applicable. Please check typing errors, and make sure conditions are met.';

  try {
    const response = yield call(request, requestURL);

    if (isValid) {
      yield put(
        actions.getVoucherMessageSuccess({
          success: response.valid_notif,
          error: false,
        }),
      );

      return;
    }

    yield put(
      actions.getVoucherMessageSuccess({
        success: false,
        error: [response?.invalid_notif || errorMessage || defaultErrorMessage],
      }),
    );
  } catch (err) {
    // in case there is other error in API let's just show a generic error message
    yield put(
      actions.getVoucherMessageSuccess({
        success: false,
        error: [errorMessage || defaultErrorMessage],
      }),
    );
  }
}

function* onSyncCartClient(action) {
  const { redirectCart, isSetCartAsNeedSync } = action;

  yield delay(600);

  if (isSetCartAsNeedSync) {
    yield delay(action.delay);
    yield put(
      actions.setCartAsNeedSync(
        !window.location.pathname.includes(URL.checkoutCart),
      ),
    );
  }

  yield put(
    actions.getCartRules({
      action: action.actionSync,
      isSyncCart: true,
    }),
  );

  yield take(constants.GET_CART_RULES_SUCCESS);

  yield put(
    actions.syncCartServer(
      action.delay,
      false,
      true,
      redirectCart,
      action.isCartUpdate,
    ),
  );

  yield delay(100);

  const cartData = yield select((state) =>
    selectors.makeSelectCartData()(state),
  );
  const cartItems =
    get(cartData, 'promotion_items') || get(cartData, 'items') || [];
  // early calculate cart items count since the sync-cart microservice is slow
  yield put(actions.setCartItemsCount(cartItems));

  if (action.isOutOfStock) {
    const invalidCartItems = yield select((state) =>
      selectors.makeSelectInvalidCartItems()(state),
    );
    if (Array.isArray(invalidCartItems)) {
      const nextItems = invalidCartItems.filter(
        (item) => item.product_sku !== action.outOfStockSku,
      );
      yield put(actions.validateCartItemsSuccess(nextItems));
    }
  }
}

function* onSyncProductThenGoCheckout(action) {
  const isShowLoader = !window.location.pathname.includes(URL.checkoutCart);
  yield delay(100);
  const cartNeedSynced = yield select((state) =>
    selectors.makeSelectCartNeedSync()(state),
  );
  if (isShowLoader) {
    yield put(globalActions.showLoader());
  }

  if (cartNeedSynced) {
    if (cartNeedSynced !== 'membership') {
      yield put(actions.syncCartServer(100, false, true, false, true));
      yield take(constants.SYNC_CART_SERVER_SUCCESS);
    } else {
      yield put(actions.setCartAsNeedSync(false));
    }

    if (action.isReloadCartInfo) {
      yield put(
        actions.getCartInfo({
          needReloadTotals: true,
          isInCheckoutPage: action.isInCheckoutPage,
        }),
      );
    }

    yield take(constants.GET_CART_INFO_SUCCESS);
  } else {
    yield put(actions.getCartTotals());
  }

  if (isShowLoader) {
    yield put(globalActions.hideLoader());
  }
  if (!action.noRedirectCart) {
    navigation.navigate(SCREEN.CHECKOUT_CART);
  }
}

function* onRemoveAllProductsIncart(action) {
  const { cartId, cartItemsCount } = action;
  if (cartId) {
    const requestURL = `${config.lambdaUrl}/carts/${cartId}`;

    yield call(axios, requestURL, {
      method: 'DELETE',
    });

    const couponCode = yield select((state) =>
      selectors.makeSelectCouponCode()(state),
    );

    if (couponCode) {
      yield put(
        actions.getVoucherMessageSuccess({
          success: false,
          error: false,
        }),
      );
    }

    yield put(
      modalActions.showCartNotificationModal({
        isRemoved: true,
        isBundled: false,
        qty: cartItemsCount,
      }),
    );

    yield put(actions.clearCartRules());
    yield put(actions.getCartInfo());
  }
}

function* onRemoveProductInCart(action) {
  const { cartId, productId, productQty, needReloadTotals } = action;
  if (cartId && productId) {
    try {
      const requestURL = `${config.lambdaUrl}/carts/${cartId}/items/${productId}`;

      yield call(req, requestURL, {
        method: 'DELETE',
      });

      if (!getAccessToken()) {
        yield put(actions.setIsGuestCartUpdated(true));
      }

      yield put(
        modalActions.showCartNotificationModal({
          isRemoved: true,
          isBundled: false,
          qty: productQty,
        }),
      );

      yield put(actions.getCartInfo());
      if (needReloadTotals) {
        yield put(actions.getCartTotals());
      }
    } catch (error) {
      console.error(error);
    }
  }
}

function* onRemoveMultipleProductsInCart(action) {
  const { cartId, invalidCartItems, redirectCart } = action;

  if (cartId && invalidCartItems) {
    try {
      const requestURL = `${config.lambdaUrl}/carts/remove-items`;
      const payload = {
        cart_id: cartId,
        cart_items: invalidCartItems,
      };

      yield put(globalActions.showLoader());

      yield call(req, requestURL, {
        method: 'POST',
        data: payload,
      });

      yield put(actions.syncCartToMgt());
      yield take(constants.SYNC_CART_MGT_FINISHED);

      yield delay(500);
      yield put(globalActions.hideLoader());

      // yield put(
      //   modalActions.showCartNotificationModal({
      //     isRemoved: true,
      //     isBundled: false,
      //     qty: productQty,
      //   })
      // )
      yield put(
        actions.getCartInfo({
          needValidate: true,
          needReloadTotals: true,
        }),
      );

      if (redirectCart) {
        delay(500);
        navigation.navigate(URL.checkoutCart);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

function* onSlowCartShow(action) {
  const isShowLoader = window.location.pathname.includes(URL.checkoutCart);
  const cartTotals = yield select((state) =>
    selectors.makeSelectCartTotals()(state),
  );

  const showCartLoading = yield select((state) =>
    selectors.makeSelectShowSlowCartLoading()(state),
  );

  if (cartTotals?.isLoading && isShowLoader && !showCartLoading) {
    yield put(actions.showSlowCartLoading());
  }
}

/**
 *
 * @param {Object} action
 * @param {number} action.delay
 * @param {boolean} action.needReloadTotals
 * @param {boolean} action.needReloadCartInfo
 * @param {boolean} action.redirectCart
 * @param {boolean} action.isCartUpdate
 * @returns {void}
 */
function* onSyncCartServer(action) {
  const { isCartUpdate, products } = action;
  const cartData = yield select((state) =>
    selectors.makeSelectCartData()(state),
  );
  let _cartId = yield select((state) => selectors.makeSelectCartId2()(state));

  if (!_cartId) {
    yield put(actions.createGuestCart());
    yield take(constants.GET_CART_INFO_SUCCESS);

    _cartId = yield select((state) => selectors.makeSelectCartId2()(state));
  }

  const cartId = _cartId;

  const getNewUpdatedQtys = (state) =>
    selectors.makeSelectNewUpdatedQtys()(state);
  let updatedQtys = yield select(getNewUpdatedQtys);

  const currentCartItemsCount = yield select((state) =>
    selectors.makeSelectCartItemsCount()(state),
  );

  const currentTempItems = yield select((state) =>
    selectors.makeSelectTempItems()(state),
  );

  const currentItems = get(cartData, 'items');

  let newQty = 0;

  for (let current of currentItems) {
    newQty += current.qty;
  }

  let payload = {
    cart_items: [],
  };

  // const currentLocation = yield select((state) =>
  //   makeSelectCurrentLocation()(state)
  // )

  // const townshipId =
  //   get(currentLocation, 'extension_attributes.township_id') ||
  //   get(currentLocation, 'barangay.id') ||
  //   get(currentLocation, 'datastore.township_id')

  let hasSpecialInstructions = false;

  // we are adding action.isCartUpdate here to update the cart price when switching address
  if (updatedQtys || action.isCartUpdate) {
    const cartData = yield select((state) =>
      selectors.makeSelectCartData()(state),
    );

    const cartItems = action.isCartUpdate
      ? cartData?.items
      : updatedQtys?.cartItems;

    const nextCartItems = cartItems.reduce((prev, cur) => {
      if (cur.product_type !== 'customer_membership') {
        const productId =
          cur?.product_id || cur?.extension_attributes?.product_data.id;

        const _isSpecialInstruction =
          !!cur?.special_instructions ||
          isSpecialInstruction(cur?.extension_attributes?.product_data);

        if (hasSpecialInstructions === false && _isSpecialInstruction) {
          hasSpecialInstructions = true;
        }

        let specialInstructions = '';

        if (_isSpecialInstruction && cur?.special_instructions) {
          specialInstructions = cur.special_instructions;
        }

        if (
          !cur.hasOwnProperty('special_instructions') &&
          action.isCartUpdate &&
          _isSpecialInstruction
        ) {
          // prettier-ignore
          specialInstructions = getSpecialInstructionValue(cur?.extension_attributes?.product_data?.options) || '';
        }

        if (productId) {
          return [
            ...prev,
            {
              product_id: productId,
              qty: cur.qty,
              ...(_isSpecialInstruction && {
                special_instructions: specialInstructions,
              }),
            },
          ];
        }
      }

      return [...prev];
    }, []);

    // @TODO need to check for special instruction
    payload.cart_items = nextCartItems;
  }

  if (isCartUpdate && getAccessToken() !== '') {
    yield put(actions.syncCartToMgt());
    yield take(constants.SYNC_CART_MGT_FINISHED);
  }

  yield put(actions.setIsSyncingProduct(true));

  // prettier-ignore
  const requestURL = `${config.lambdaUrl}/stores/${getStoreView()}/carts/${cartId}/sync`;

  if (!getAccessToken()) {
    yield put(actions.setIsGuestCartUpdated(true));
  }

  if (isCartUpdate === false && products) {
    yield put(actions.setCartAsNeedSync(false));

    for (let product of products) {
      if (get(product, 'data.id')) {
        payload.cart_items.push({
          product_id: get(product, 'data.id'),
          qty: get(product, 'qty', 0),
        });
      }
    }
  }

  if (payload.cart_items.length === 0) {
    return;
  }

  try {
    const res = yield call(req, requestURL, {
      method: 'POST',
      data: payload,
    });

    yield put(actions.setIsSyncingProduct(false));

    const requireLogin = get(res, 'data.require_login', false);

    if (requireLogin) {
      yield put(modalActions.showAddMoreItemModal(requireLogin));
    }

    let diff = newQty - currentCartItemsCount;

    if (get(res, 'data.data.require_login') === true) {
      // to disable reloading of page
      return false;
    }

    if (diff !== 0) {
      yield put(
        modalActions.showCartNotificationModal({
          isRemoved: diff < 0,
          isBundled: false,
          qty: Math.abs(diff),
        }),
      );
    }

    // @TODO will need to remove this since totals-information will need to wait
    // for carts/mine to finish
    if (action.needReloadTotals) {
      yield put(actions.getCartTotals());
    }

    if (action.needReloadCartInfo) {
      yield put(
        actions.getCartInfo({
          syncProductNoRedirect: !hasSpecialInstructions,
          needReloadTotals: true,
        }),
      );
    }

    if (action.redirectCart) {
      navigation.navigate(URL.checkoutCart);
    }

    let resultItems = get(res, 'data.data', []);

    let updatedItems = resultItems?.items.map((val) => {
      const matchingItem = currentItems.find(
        (itemVal) => val.sku === itemVal.sku,
      );
      if (matchingItem) {
        return {
          ...val,
          extension_attributes: matchingItem.extension_attributes,
        };
      }
      return val;
    });
    const missingItems = currentTempItems.filter((tempItem) => {
      return !resultItems.items.some((resItem) => resItem.sku === tempItem.sku);
    });

    resultItems['items'] = updatedItems;

    if (missingItems) {
      resultItems['items'] = cartData.items.concat(missingItems);
    }
    yield put(globalActions.hideLoader());
    // yield put(actions.syncCartServerSuccess(resultItems))
  } catch (err) {
    if (action.redirectCart) {
      navigation.navigate(URL.checkoutCart);
    }
    yield put(globalActions.hideLoader());
    yield put(actions.syncCartServerSuccess([]));

    let errorMessage = err?.response?.data?.error?.message;

    if (typeof errorMessage != 'string' && typeof errorMessage != 'undefined') {
      errorMessage = errorMessage[0].message;
    }

    if (errorMessage) {
      showError(errorMessage);
    } else {
      showError('An unexpected error occurred:');
    }

    // return setTimeout(() => {
    //   window.location.reload()
    // }, 2000)
  }
}

function* onGuestCreateCart() {
  yield put(actions.createCart());
  yield take(constants.CREATE_CART_SUCCESS);

  const getCartMaskId = (state) => selectors.makeSelectCartMaskId()(state);
  const cartMaskId = yield select(getCartMaskId);

  // prettier-ignore
  let requestURL = `${config.lambdaUrl}/cached/guest-carts/${cartMaskId}/${getStoreView()}`;
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'max-age=0',
  };

  const result = yield call(req, requestURL, {
    method: 'GET',
    headers,
  });

  const res = result?.data?.data;

  let cartData = cartHelper.calculateCart(res);

  yield put(actions.getCartInfoSuccess(cartData));
}

function* onGetCartRules(action) {
  const requestURL = `${config.baseUrl}/api/v1/promotions/cartrule`;
  yield delay(action.delay);

  const cartData = yield select((state) =>
    selectors.makeSelectCartData()(state),
  );

  const cartRuleRequestData = map(cartData.items, (cartItem) => ({
    sku: cartItem.sku,
    qty: cartItem.qty,
  }));

  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(cartRuleRequestData),
    });

    const newCartData = promotionHelper.applyRulesToCart(
      cartData,
      res,
      action.actionSync,
    );

    yield put(
      actions.getCartRulesSuccess({
        rules: res,
        cart: newCartData,
      }),
    );
  } catch (err) {
    const mockUpCartRules = map(cartData.items, function (cartItem) {
      const mockupRule = {
        discount: null,
        promotion_name: null,
        promotion_type: 'normal',
        skus: {},
      };
      mockupRule.skus[cartItem.sku] = {};
      mockupRule.skus[cartItem.sku].qty = cartItem.qty;
      return mockupRule;
    });

    yield put(
      actions.getCartRulesSuccess({
        rules: mockUpCartRules,
        cart: promotionHelper.applyRulesToCart(
          cartData,
          mockUpCartRules,
          action.actionSync,
        ),
      }),
    );
  }
}

function* onPersistRehydrate(action) {
  const pathname = window.location.pathname;

  if (pathname !== URL.chatPlugin) {
    if (action.key === 'global' && get(action, 'payload.accessToken')) {
      const accessToken = get(action, 'payload.accessToken');
      const currentLocation = get(action, 'payload.currentLocation');
      const storeCode = get(currentLocation, 'storeCode');
      const userEmail = get(action, 'payload.currentUserEmail');

      setStoreCode(storeCode);
      setAccessToken(accessToken);

      if (userEmail) {
        setUserEmailApi(userEmail);
      }

      if (accessToken) {
        yield put(
          globalActions.getUserInfo({
            needUpdateID: true,
          }),
        );
      }
    }

    if (
      action.key === 'auth' &&
      get(action, 'payload.lambdaAccessToken.access_token')
    ) {
      const nodeToken = get(action, 'payload.lambdaAccessToken.access_token');
      const expiresIn = get(action, 'payload.lambdaAccessToken.expires_in');

      setNodeTokenApi(nodeToken);
      setExpiryApi(expiresIn);
    }

    if (action.key === 'global') {
      const currentLocation = get(action, 'payload.currentLocation');
      const storeCode = get(currentLocation, 'storeCode');

      setStoreCode(storeCode);
      yield put(rehydrateStoreViewCode(storeCode));
    }

    delay(40);
    if (action.key === 'checkout') {
      if (getAccessToken()) {
        yield put(actions.getCartInfo());
      } else {
        const cartMaskId = get(action, 'payload.cartMaskId');
        if (cartMaskId) {
          yield put(actions.getCartInfo());
        }
      }
    }

    yield delay(100);
    yield put(globalActions.setPersistorLoaded());
  } else {
    yield put(globalActions.setPersistorLoaded());
  }
}

function* onRestoreCart(action) {
  const requestURL = `${config.apiUrl}/carts/mine/restore-quote`;
  yield put(checkoutActions.isRestoreCartProcessing(true));
  try {
    const res = yield call(request, requestURL, { method: 'GET' });
    yield put(actions.createCart());
    yield put(checkoutActions.isRestoreCartProcessing(false));
    if (!res.error && !action.noRedirectCart) {
      navigation.navigate(SCREEN.CHECKOUT_CART);
    }
  } catch (err) {
    yield put(checkoutActions.isRestoreCartProcessing(false));
  }
}

// support mapping shipping address after payment
// eslint-disable-next-line require-yield
function* onSetShippingAddress(action) {
  saveItem(localStore.shippingAddress, action.address);
}

function* onGetLastOrderOOS(action) {
  const requestURL = `${config.apiUrl}/ld-customers/last-order-oos`;
  try {
    const res = yield call(request, requestURL, { method: 'GET' });
    yield put(checkoutActions.getLastOrderOOSSuccess(res));
  } catch (err) {
    yield put(checkoutActions.getLastOrderOOSFailed());
  }
}

function* onGetPaymentCards(action) {
  if (sessionStorage.getItem('is_customer_support')) {
    return;
  }

  yield put(globalActions.showLoader());
  const getSelectedPaymentCard = (state) =>
    selectors.makeSelectSelectedPaymentCard()(state);
  const selectedPaymentCard = yield select(getSelectedPaymentCard);

  const requestURL = `${config.baseUrl}/rest/V1/maya-vault/customer-card`;
  try {
    const res = yield call(request, requestURL, { method: 'GET' });
    yield put(checkoutActions.getPaymentCardsSuccess(res));

    if (!selectedPaymentCard && res) {
      const defaultPaymentCard = res.find((x) => x.default === true) || res[0];
      trackEvent(trackingEvent.clickCard);
      yield put(checkoutActions.setPaymentCard(defaultPaymentCard));
    }
    yield put(globalActions.hideLoader());
  } catch (err) {
    console.log('err', err);
    yield put(globalActions.hideLoader());
    yield put(checkoutActions.getPaymentCardsFailed(err));
  }
}

function* onAddPaymentCards(action) {
  const currentUser = yield select(makeSelectCurrentUser());
  const requestURL = `${config.baseUrl}/paymaya/card/createCustomerCard`;
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({ ...action?.params, customerId: currentUser?.id }),
    });
    yield put(checkoutActions.AddPaymentCardSuccess(res));
    yield put(checkoutActions.getPaymentCards());
    yield put(modalActions.hideAddCardModal());
    yield put(modalActions.hideAddCardModal());
  } catch (err) {
    console.log('err', err);
    yield put(checkoutActions.AddPaymentCardFailed(err));
  }
}

/**
 *
 * @param {Object} action
 * @param {string} action.pathname
 * @param {boolean} action.isStoreCodeChanged
 */
function* onSyncCartWhenAddressChanged(action) {
  const { pathname, isStoreCodeChanged } = action;

  try {
    const cartData = yield select((state) =>
      selectors.makeSelectCartData()(state),
    );

    if (size(cartData.items) > 0) {
      yield put(checkoutActions.syncCartServer(100, false, true, false, true));
      yield take(constants.SYNC_CART_SERVER_SUCCESS);
      yield delay(200);
    }

    if (isStoreCodeChanged && pathname !== '/') {
      yield delay(500);
      window.location.reload();
    }
  } catch (e) {
    console.log('error :', e);
  }
}

function* onUpdateCart(action) {
  const { data, qty, special_instructions } = action.payload;

  yield put(globalActions.showLoader());
  yield put(
    actions.syncCartClient(
      [
        {
          data: data,
          qty: qty || 1,
          special_instructions,
        },
      ],
      {
        delay: 500,
        isSetCartAsNeedSync: false,
      },
    ),
  );
  yield take(constants.SYNC_CART_SERVER_SUCCESS);
  yield take(constants.GET_CART_INFO_SUCCESS);
  yield delay(200);
  yield put(globalActions.hideLoader());
}

function* onRunSuccessProcess(action) {
  const { orderId } = action.payload;

  yield put(
    actions.getOrderById({
      orderId: orderId,
      reloadShippingAddress: true,
    }),
  );

  yield take(constants.GET_ORDER_BYID_SUCCESS);

  const createdOrder = yield select((state) =>
    selectors.makeSelectCreatedOrder()(state),
  );

  const requestURL = `${CART_MS_URL}/deactivate/mgtId/${createdOrder.quote_id}`;

  yield call(req, requestURL, {
    method: 'DELETE',
  });

  yield put(actions.createCart());
  yield put(globalActions.getUserInfo());
  yield put(getSplashAd({ location: SPLASH_ADS_LOCATION.SUCCESSFUL_PURCHASE }));
}

function* updateAddressBeforeSyncing(action) {
  const { isStoreCodeChanged, pathName, history } = action.payload;

  yield put(updateShippingAddress(false, { isUpdateAddress: true }));
  yield take(constants.UPDATE_SHIPPING_ADDRESS_SUCCESS);
  yield delay(100);
  yield put(globalActions.syncCartAddressChange(pathName, isStoreCodeChanged));

  if (isStoreCodeChanged && pathName === '/') {
    history.push({
      pathname: pathName,
      state: { currentLocationChanged: true },
    });
  }
}

function* syncCartToMgt() {
  try {
    const cartId = yield select((state) => selectors.makeSelectCartId()(state));
    let mgtSyncURL = `${config.lambdaUrl}/sync-cart/mgt`;

    yield call(req, mgtSyncURL, {
      method: 'POST',
      data: {
        cart_id: cartId,
      },
    });
  } catch (e) {
    //
  } finally {
    yield put(actions.syncCartToMgtFinished());
  }
}

function* onSavePayPlaceOrder(action) {
  const { payload } = action;
  const urls = payload.urls;
  try {
    let result = {};
    const paymentInfoRes = payload.paymentInfoRes;
    const mgtPayload = payload.mgtPayload;
    for (let index = 0; index < urls.length; index++) {
      const mgtUrl = urls[index].mgtUrl;
      const { res, canceled } = yield race({
        res: call(request, mgtUrl, {
          method: 'POST',
          body: mgtPayload,
          headers: {
            Sourcecode: get(paymentInfoRes, 'data.data.Sourcecode', 'default'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }),
        canceled: take(constants.CANCEL_UPDATE_PAYMENT_INFORMATION),
      });

      if (canceled) {
        yield delay(1000);
        yield put(globalActions.hideLoader());
        return yield put(checkoutActions.restoreCart());
      }
      if (index === urls.length - 1) {
        result = { res, canceled };
      }
    }
    yield put(checkoutActions.savePayPlaceOrderSuccess(result.res));
  } catch (err) {
    console.log('err', err);
    showError(get(err, 'message') || 'Failed to place order');
    navigation.navigate(SCREEN.CHECKOUT_TIMESLOTS);
    yield put(globalActions.hideLoader());
    yield put(checkoutActions.isSubmittedPayment(false));
    yield put(actions.updatePaymentInformationFailed(err));
  }
}

function* onLoadDeliveryAddressScreen() {
  try {
    yield put(updateShippingAddress(false, { isUpdateAddress: true }));
    yield take(constants.UPDATE_SHIPPING_ADDRESS_SUCCESS);
    yield put(actions.syncCartToMgt());
    yield take(constants.SYNC_CART_MGT_FINISHED);
    yield put(globalActions.getUserInfo({ needValidateAddress: true }));
    yield put(
      checkoutActions.selectDeliveryTimeslot({ needValidateAddress: true }),
    );
    yield put(checkoutActions.validateCartItems());
  } catch (err) {
    console.log('err', err);
  }
}

// Individual exports for testing
export default function* checkoutSaga() {
  yield takeLatest(constants.CREATE_CART, onCreateCart);
  yield takeLatest(constants.GET_CART_TOTALS, onGetCartTotals);
  yield takeLatest(constants.GET_CART_INFO, onGetCartInfo);
  yield takeLatest(constants.VALIDATE_CART_ITEMS, onValidateCartItems);
  yield takeLatest(constants.GET_DELIVERY_TIMESLOTS, onGetDeliveryTimeslots);
  yield takeLatest(
    constants.VALIDATE_DELIVERY_TIMESLOT,
    onValidateDeliveryTimeslot,
  );
  yield takeLatest(constants.GET_SHIPPING_METHODS, onGetShippingMethods);
  yield takeLatest(
    constants.ESTIMATE_SHIPPING_METHODS,
    onEstimateShippingMethods,
  );
  yield takeLatest(constants.UPDATE_SHIPPING_ADDRESS, onUpdateShippingAddress);
  yield takeLatest(
    constants.UPDATE_PAYMENT_INFORMATION,
    onUpdatePaymentInformation,
  );
  yield takeLatest(constants.GET_ORDER_BYID, onGetOrderById);
  yield takeLatest(constants.GET_PAYMENT_METHODS, onGetPaymentMethods);
  yield takeLatest(constants.APPLY_COUPON, onApplyCoupon);
  yield takeLatest(constants.SYNC_CART_CLIENT, onSyncCartClient);
  yield takeLatest(
    constants.REMOVE_ALLPRODUCTS_INCART,
    onRemoveAllProductsIncart,
  );
  yield takeLatest(constants.REMOVE_PRODUCT_IN_CART, onRemoveProductInCart);
  yield takeLatest(
    constants.REMOVE_MULTIPLE_PRODUCTS_IN_CART,
    onRemoveMultipleProductsInCart,
  );
  yield takeLatest(constants.SYNC_CART_SERVER, onSyncCartServer);
  yield takeLatest(constants.GET_CART_RULES, onGetCartRules);
  yield takeLatest(
    constants.SYNC_PRODUCT_THEN_GOCHECKOUT,
    onSyncProductThenGoCheckout,
  );
  yield takeLatest(constants.RESTORE_CART, onRestoreCart);
  yield takeLatest(constants.SET_SHIPPING_ADDRESS, onSetShippingAddress);
  yield takeLatest(constants.GET_LAST_ORDER_OOS, onGetLastOrderOOS);
  yield takeEvery(constants.GET_STORES_TIME_SLOTS, onGetStoresTimeslots);
  yield takeLatest(constants.GET_PAYMENT_CARDS, onGetPaymentCards);
  yield takeLatest(constants.ADD_PAYMENT_CARDS, onAddPaymentCards);
  yield takeLatest('persist/REHYDRATE', onPersistRehydrate);
  yield debounce(3000, constants.SYNC_CART_CLIENT, onSlowCartShow);
  yield debounce(3000, constants.SYNC_PRODUCT_THEN_GOCHECKOUT, onSlowCartShow);
  yield takeLatest(SYNC_CART_ADDRESS_CHANGED, onSyncCartWhenAddressChanged);
  yield takeLatest(constants.GET_VOUCHER_MESSAGE, onGetVoucherMessage);
  yield takeLatest(constants.UPDATE_CART, onUpdateCart);
  yield takeLatest(constants.RUN_PAYMENT_SUCCESS_PROCESS, onRunSuccessProcess);
  yield takeLatest(
    constants.UPDATE_ADDRESS_BEFORE_SYNCING,
    updateAddressBeforeSyncing,
  );
  yield takeLatest(constants.SYNC_CART_TO_MGT, syncCartToMgt);
  yield takeLatest(constants.SAVE_PAY_PLACE_ORDER, onSavePayPlaceOrder);
  yield takeLatest(
    constants.LOAD_DELIVERY_ADDRESS_SCREEN,
    onLoadDeliveryAddressScreen,
  );
  yield takeLatest(constants.CREATE_GUEST_CART, onGuestCreateCart);

  yield all([checkoutCartSaga()]);
}
